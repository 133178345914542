































import { Component, Vue, Prop } from 'vue-property-decorator';

import ItemRedaction from '../../../../components/ItemRedaction/ItemRedaction.vue';
import ListItemRedactionLoading from '../../../../components/ListItemRedaction/ListItemRedactionLoading.vue';

import Loading from '@/components/Loading/Loading.vue';

import { MyRedactions as IMyRedactions } from '../../IMyRedaction';

@Component({
  components: {
    ItemRedaction,
    ListItemRedactionLoading,
    Loading
  }
})
export default class ContainerSectionMyRedactions extends Vue {
  @Prop() isLoading!: boolean;
  @Prop() hasMoreRedaction!: boolean;
  @Prop() list_redaction!: Array<IMyRedactions>;

  nextPageRedaction() {
    this.$emit('nextPageRedactions');
  }

  emitModalRedactionRejected(idMyRedaction: number) {
    this.$emit('emitModalRedactionRejected', idMyRedaction);
  }
}
