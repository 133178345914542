import { render, staticRenderFns } from "./MyRedactions.vue?vue&type=template&id=3a7fd4a9&scoped=true&"
import script from "./MyRedactions.vue?vue&type=script&lang=ts&"
export * from "./MyRedactions.vue?vue&type=script&lang=ts&"
import style0 from "./MyRedactions.scss?vue&type=style&index=0&id=3a7fd4a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a7fd4a9",
  null
  
)

export default component.exports